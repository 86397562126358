import React, { useState, useEffect } from "react";

import { Modal, Form, Input, Select } from "antd";

const EditTipModal = (props) => {
  const [password, setPassword] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");

  useEffect(() => {
    if (props.visible && props.tip != null) {
      setTeam1(props.tip.team1);
      setTeam2(props.tip.team2);
    }
  }, [props.visible]);

  const afterClose = () => {
    setPassword("");
    setTeam1("");
    setTeam2("");
  };

  const editTip = () => {
    props.editTip(password, team1, team2);
  };

  return (
    <Modal
      centered
      visible={props.visible}
      okText="Übernehmen"
      okButtonProps={{
        disabled: password.trim() === "" || team1 === "" || team2 === "",
      }}
      cancelText="Abbrechen"
      onCancel={props.onCancel}
      onOk={editTip}
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
      afterClose={afterClose}
    >
      <Form layout="vertical">
        <Form.Item
          label="Passwort"
          validateStatus={password === "" ? "error" : ""}
        >
          <Input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Team 1" validateStatus={team1 === "" ? "error" : ""}>
          <Select
            value={team1}
            onChange={(value) => {
              setTeam1(value);
            }}
            options={props.teams.map((team) => {
              return {
                label: team,
                value: team,
              };
            })}
          />
        </Form.Item>
        <Form.Item label="Team 2" validateStatus={team2 === "" ? "error" : ""}>
          <Select
            value={team2}
            onChange={(value) => {
              setTeam2(value);
            }}
            options={props.teams.map((team) => {
              return {
                label: team,
                value: team,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTipModal;
