import React, { useCallback, useEffect, useState, useRef } from "react";

import { Button, Typography, Tooltip, Modal, Row, Col } from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import TipModal from "./TipModal";
import RemoveTipModal from "./RemoveTipModal";

import headerImage from "./einladung.png";
import "./App.css";
import EditTipModal from "./EditTipModal";

const { Text } = Typography;

function App() {
  const [tippsUrl, setTippsUrl] = useState("/tipps");
  const [tipps, setTipps] = useState([]);

  const [teamsUrl, setTeamsUrl] = useState("/teams");
  const [teams, setTeams] = useState([]);

  const [playoffTeamsUrl, setPlayoffTeamsUrl] = useState("/playoffteams");
  const [playoffTeams, setPlayoffTeams] = useState([]);

  const [tipModalVisible, setTipModalVisible] = useState(false);
  const [removeTipModalVisible, setRemoveTipModalVisible] = useState(false);
  const [editTipModalVisible, setEditTipModalVisible] = useState(false);

  const [hideTipStuff, setHideTipStuff] = useState(false);

  const lastClickedTipRef = useRef(null);

  const fetchTipps = useCallback(() => {
    fetch(tippsUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`status ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setTipps(json.tipps);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [tippsUrl]);

  const fetchTeams = useCallback(() => {
    fetch(teamsUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`status ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setTeams(json.teams);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [teamsUrl]);

  const fetchPlayoffTeams = useCallback(() => {
    fetch(playoffTeamsUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`status ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setPlayoffTeams(json.teams);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [playoffTeamsUrl]);

  useEffect(() => {
    fetchTipps();
    fetchTeams();
    fetchPlayoffTeams();
  }, []);

  useEffect(() => {
    if (playoffTeams.length > 0) {
      const playoffDate = new Date(2023, 0, 14, 22, 0, 0, 0);
      const date = new Date();

      if (date.getTime() >= playoffDate.getTime()) {
        setHideTipStuff(true);
      } else {
        setHideTipStuff(false);
      }
    }
  }, [playoffTeams]);

  const showTipModal = () => {
    setTipModalVisible(true);
  };

  const hideTipModal = () => {
    setTipModalVisible(false);
  };

  const hideEditTipModal = () => {
    lastClickedTipRef.current = null;
    setEditTipModalVisible(false);
  };

  const hideRemoveTipModal = () => {
    lastClickedTipRef.current = null;
    setRemoveTipModalVisible(false);
  };

  const addTip = (name, password, team1, team2) => {
    const hash = window.forge_sha256(password);
    fetch(
      "/addtip?" +
        new URLSearchParams({
          name,
          password: hash,
          team1,
          team2,
        }).toString()
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`status ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        if (json.success) {
          fetchTipps();
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setTipModalVisible(false);
  };

  const editTip = (password, team1, team2) => {
    fetch(
      "/edittip?" +
        new URLSearchParams({
          name: lastClickedTipRef.current.name,
          password: window.forge_sha256(password),
          team1,
          team2,
        }).toString()
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`status ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        lastClickedTipRef.current = null;
        if (json.success) {
          fetchTipps();
        } else {
          Modal.error({
            title: "Error",
            content: "Falschen Passwort!",
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setEditTipModalVisible(false);
  };

  const removeTip = (password) => {
    fetch(
      "/removetip?" +
        new URLSearchParams({
          password: window.forge_sha256(password),
          name: lastClickedTipRef.current.name,
        }).toString()
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`status ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        lastClickedTipRef.current = null;
        if (json.success) {
          fetchTipps();
        } else {
          Modal.error({
            title: "Error",
            content: "Falschen Passwort!",
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setRemoveTipModalVisible(false);
  };

  const renderTipIsGoodIcon = (icon) => {
    if (!hideTipStuff) {
      return null;
    } else {
      return icon;
    }
  };

  const renderTeamButtons = (tip) => {
    if (!hideTipStuff) {
      return (
        <Col span={6} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Tipp editieren">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  lastClickedTipRef.current = tip;
                  setEditTipModalVisible(true);
                }}
              ></Button>
            </Tooltip>
            <Tooltip title="Tipp löschen">
              <Button
                style={{ marginLeft: "12px" }}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => {
                  lastClickedTipRef.current = tip;
                  setRemoveTipModalVisible(true);
                }}
              ></Button>
            </Tooltip>
          </div>
        </Col>
      );
    } else {
      return null;
    }
  };

  const renderTeams = () => {
    return tipps.map((tip, index) => {
      const { team1, team2 } = tip;
      const tipIsGood =
        playoffTeams.indexOf(team1) !== -1 &&
        playoffTeams.indexOf(team2) !== -1 &&
        team1 !== team2;
      let icon = null;
      if (tipIsGood) {
        icon = (
          <CheckCircleOutlined
            style={{ color: "green", fontSize: "30px", paddingRight: "10px" }}
          />
        );
      } else {
        icon = (
          <CloseCircleOutlined
            style={{ color: "red", fontSize: "30px", paddingRight: "10px" }}
          />
        );
      }

      return (
        <Row
          key={index.toString()}
          gutter={8}
          align="center"
          justify="center"
          className="Tip-Row"
        >
          <Col
            span={hideTipStuff ? 8 : 6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              {renderTipIsGoodIcon(icon)}
              <Text style={{ color: "white" }} ellipsis>
                {tip.name}
              </Text>
            </div>
          </Col>
          <Col
            span={hideTipStuff ? 8 : 6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Text style={{ color: "white", paddingRight: "10px" }} ellipsis>
                {team1}
              </Text>
            </div>
          </Col>
          <Col
            span={hideTipStuff ? 8 : 6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Text style={{ color: "white", paddingLeft: "10px" }} ellipsis>
                {team2}
              </Text>
            </div>
          </Col>
          {renderTeamButtons(tip)}
        </Row>
      );
    });
  };

  const renderAddTipButton = () => {
    if (hideTipStuff) {
      return null;
    } else {
      return (
        <Button
          type="primary"
          size="large"
          onClick={showTipModal}
          style={{ marginTop: "15px", marginBottom: "15px" }}
        >
          Tipp abgeben
        </Button>
      );
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={headerImage} alt="Logo" style={{ maxWidth: "100%" }} />
      </header>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <Text
          style={{ color: "lightgray", fontStyle: "italic", paddingTop: "8px" }}
        >
          Tippapgabe schließt am 14.01.2023 um 22:00 Uhr
        </Text>
        {renderAddTipButton()}
        {renderTeams()}
      </div>
      <TipModal
        visible={tipModalVisible}
        onCancel={hideTipModal}
        teams={teams}
        addTip={addTip}
        tipps={tipps}
      />
      <EditTipModal
        visible={editTipModalVisible}
        onCancel={hideEditTipModal}
        editTip={editTip}
        teams={teams}
        tip={lastClickedTipRef.current}
      />
      <RemoveTipModal
        visible={removeTipModalVisible}
        onCancel={hideRemoveTipModal}
        removeTip={removeTip}
      />
    </div>
  );
}

export default App;
