import React, { useState } from "react";

import { Modal, Form, Input } from "antd";

const RemoveTipModal = (props) => {
  const [password, setPassword] = useState("");

  const afterClose = () => {
    setPassword("");
  };

  const removeTip = () => {
    props.removeTip(password);
  };

  return (
    <Modal
      centered
      visible={props.visible}
      okText="Übernehmen"
      okButtonProps={{
        disabled: password.trim() === "",
      }}
      cancelText="Abbrechen"
      onOk={removeTip}
      onCancel={props.onCancel}
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
      afterClose={afterClose}
    >
      <Form layout="vertical">
        <Form.Item
          label="Passwort"
          validateStatus={password === "" ? "error" : ""}
        >
          <Input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RemoveTipModal;
