import React, { useState } from "react";

import { Modal, Form, Input, Select } from "antd";

const TipModal = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");

  const afterClose = () => {
    setName("");
    setPassword("");
    setTeam1("");
    setTeam2("");
  };

  const addTip = () => {
    props.addTip(name, password, team1, team2);
  };

  const validateName = () => {
    if (name === "") return "error";

    for (let i = 0; i < props.tipps.length; i++) {
      const tip = props.tipps[i];
      if (tip.name === name) return "error";
    }

    return "";
  };

  const getNameHelp = () => {
    for (let i = 0; i < props.tipps.length; i++) {
      const tip = props.tipps[i];
      if (tip.name === name) return "Name wird bereits verwendet.";
    }

    return "";
  };

  return (
    <Modal
      centered
      visible={props.visible}
      okText="Übernehmen"
      okButtonProps={{
        disabled:
          name.trim() === "" ||
          password.trim() === "" ||
          team1 === "" ||
          team2 === "" ||
          validateName() === "error",
      }}
      cancelText="Abbrechen"
      onCancel={props.onCancel}
      onOk={addTip}
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
      afterClose={afterClose}
    >
      <Form layout="vertical">
        <Form.Item
          label="Name"
          validateStatus={validateName()}
          help={getNameHelp()}
        >
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Passwort"
          validateStatus={password === "" ? "error" : ""}
        >
          <Input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Team 1" validateStatus={team1 === "" ? "error" : ""}>
          <Select
            value={team1}
            onChange={(value) => {
              setTeam1(value);
            }}
            options={props.teams.map((team) => {
              return {
                label: team,
                value: team,
              };
            })}
          />
        </Form.Item>
        <Form.Item label="Team 2" validateStatus={team2 === "" ? "error" : ""}>
          <Select
            value={team2}
            onChange={(value) => {
              setTeam2(value);
            }}
            options={props.teams.map((team) => {
              return {
                label: team,
                value: team,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TipModal;
